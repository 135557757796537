import { createGlobalStyle } from "styled-components";
import Kanit_ExtraBoldItalic from "./assets/fonts/Kanit-ExtraBoldItalic.ttf";
import Kanit_ExtraBold from "./assets/fonts/Kanit-ExtraBold.ttf";
import Kanit_Regular from "./assets/fonts/Kanit-Regular.ttf";

const GlobalStyle = createGlobalStyle`

@font-face{
  font-family: Main;
  src: url(${Kanit_Regular});
}

@font-face{
        font-family: Menu;
        src: url(${Kanit_ExtraBold});
    }
   
@font-face{
      font-family: Hover;
      src: url(${Kanit_ExtraBoldItalic});
  }   

  :root {
--footer-height: 1rem;

  } 
  
html, body {
  margin: 0;
  padding: 0;
}  

#root {
  min-height: 100vh;
  position: relative;
}

body {
        background-color: black;
        color:#fffdf3;
        font-family: Main;
        font-size: 1.2rem;
    
      
       
      }

#root::after{
content: '';
display:block;
height: var(--footer-height);
      }


      
   



`;
export default GlobalStyle;