import { useEffect, useState } from "react";
import styled from "styled-components";
// Import 2 images
import Image1 from "../assets/img/selfie.jpg";
import Image2 from "../assets/img/selfie2.jpg";

// Easier to have the images in an array so we can access them with an index
const images = [Image1, Image2];

// Styled image component with a prop called 'active' that we use to decide if
// we want to display the image or not
const Image = styled.img<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s;
  object-fit: cover;
  opacity: ${(props) => (props.active ? "1" : "0")};
`;

// Main component
const SwitchImage = (props: { className?: string }) => {
  // Use a state to save the currently active image, whenever this state is
  // changed (IMPORTANT: Use setCurrentImage, don't set currentImage directly)
  // the component is rerendered, we use the value of the state below, to decide
  // which of our images we should render

  const [hovered, setHovered] = useState(false);

  // Render
  return (
    // Use figure as a semantic HTML element instead of a div (totally optional and
    // functionally (basically) identical but the correct thing to do for SEO and
    // disabled people who rely on screenreaders etc.)
    <figure
      // When we enter into the component with our cursor, we want to switch the image
      onMouseEnter={() => {
        setHovered((current) => !current);
      }}
      // ... and switch it back once we leave
      onMouseLeave={() => {
        setHovered(false);
      }}
      className={props.className}
    >
      {/* Our Images, we select which one is visible by passing the 'active' prop 
        to their component */}
      <Image src={images[0]} alt="img1" active={true} />
      <Image src={images[1]} alt="img2" active={hovered} />
    </figure>
  );
};

export default SwitchImage;
