import styled from "styled-components";
import logoImg from "../assets/img/logo192.png";

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
`;

const FooterName = styled.div`
  font-size: 0.8rem;
  bottom: 0;
  position: absolute;
  height: var(--footer-height);
`;

const ImgFooter = styled.img`
  width: 50px;
  height: 50px;

  align-items: center;
`;

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <FooterName>© brunaaart</FooterName>
        <ImgFooter src={logoImg} />
      </FooterWrapper>
    </>
  );
};

export default Footer;
