import React from "react";
import About from "./components/About";
import Home from "./components/Home";
import Header from "./components/Header";
import Nft from "./components/Nft";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import CanvasComponent from "./components/CanvasComponent";

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/nft" element={<Nft />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
