import styled from "styled-components";
import nft1 from "../assets/img/gallery/self-reflection.jpg";
import nft2 from "../assets/img/gallery/thunder-goddess.jpg";
import nft3 from "../assets/img/gallery/mother_earth.mp4";

const CategoryWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 0rem;
  padding-top: 5rem;
  margin-left: 0rem;
  align-items: center;
  justify-content: center;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    margin-right: 10rem;
    padding-top: 20rem;
    margin-left: 10rem;
  }
`;
const CategoryWrapper2 = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 0rem;
  padding-top: 5rem;
  margin-left: 0rem;
  align-items: center;
  justify-content: center;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    margin-right: 10rem;
    padding-top: 5rem;
    margin-left: 10rem;
  }
`;

const Wrapper = styled.div`
  display: grid;
  gap: 0rem;
  grid-template-columns: 1fr;
  grid-auto-columns: 50px;
  grid-auto-flow: dense;
  position: relative;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    display: grid;
    gap: 0rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    position: relative;

    align-items: start;
  }
`;
const Nftwrapper = styled.div`
  width: 200px;
  text-align: center;
  gap: 0rem;
  position: relative;
  margin-top: 3rem;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  @media (min-width: 640px) {
    width: 300px;
  }

  @media (min-width: 768px) {
    width: 380px;
  }

  @media (min-width: 1024px) {
    width: 480px;
  }

  @media (min-width: 1200px) {
    // display: grid;
    gap: 0rem;
    width: 380px;
    position: relative;
    margin-top: 3rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
const aspectRatio = "4 / 5";

const NftImg = styled.img`
  object-fit: cover;
  width: 100%;

  aspect-ratio: ${aspectRatio};
  grid-column: span 1fr;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    object-fit: cover;
    width: 100%;

    aspect-ratio: ${aspectRatio};
    grid-column: span 1fr;
    :hover {
      filter: opacity(1);
    }
  }
`;
const NftVideo = styled.video`
  object-fit: cover;
  width: 100%;
  aspect-ratio: ${aspectRatio};
  grid-column: span 1fr;
  :hover {
    filter: opacity(1);
  }
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    object-fit: cover;
    width: 100%;
    aspect-ratio: ${aspectRatio};
    grid-column: span 1fr;
    :hover {
      filter: opacity(1);
    }
  }
`;
const Link = styled.a`
  font-family: Main;
  text-decoration: lightpink underline;
  text-decoration-thickness: 2px;
  transition: color 0.2s;
  font-size: 1rem;
  color: white;
  margin: 1rem 1rem;
  text-align: center;

  :hover {
    text-decoration-thickness: 4px;
  }
  :visited {
  }
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    font-family: Main;
    text-decoration: lightpink underline;
    text-decoration-thickness: 2px;
    transition: text-decoration-thickness 0.2s;
    font-size: 1.5rem;
    color: white;
    margin: 1rem 1rem;
    text-align: center;

    :hover {
      text-decoration-thickness: 4px;
    }
    :visited {
    }
  }
`;
const H1 = styled.div`
  font-size: 2rem;
  text-align: center;
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`;

const Nft = () => {
  return (
    <>
      <CategoryWrapper>
        <H1>Grants.art</H1>
        <Wrapper>
          <Nftwrapper>
            <NftImg src={nft1} />
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://grants.art/get-nft/56"
            >
              Distorted reality
            </Link>
          </Nftwrapper>
          <Nftwrapper>
            <NftImg src={nft2} />
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://grants.art/gallery/130"
            >
              Genesis Grant Exhibition
            </Link>
          </Nftwrapper>
          <Nftwrapper>
            <NftVideo src={nft3} autoPlay loop />
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://grants.art/empowering-women-artists/88"
            >
              Paris Hilton Exhibition
            </Link>
          </Nftwrapper>
        </Wrapper>
      </CategoryWrapper>
      <CategoryWrapper2>
        <H1>Hic et nunc</H1>

        <Nftwrapper>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://hicetnunc.art/bruna"
          >
            brunaaart Profile
          </Link>
        </Nftwrapper>
      </CategoryWrapper2>
    </>
  );
};

export default Nft;
