import styled from "styled-components";
import LogoAni from "../assets/videos/brunaaart_logo_ani_10.mp4";
import Gallery from "./Gallery";
import { Canvas, useFrame } from "@react-three/fiber";
import { useRef } from "react";
import SwitchImage from "./SwitchImage_copy";

const VideoWrapper = styled.figure`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LandingWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  z-index: -1;
`;

const ImgGallery = styled.div``;

const MyCanvas = styled(Canvas)`
  width: 100px;
  height: 100px;
  display: flex;
  position: absolute;
  align-items: center;
  margin-top: 10rem;
  background-color: white;
`;

const Box = () => {
  const ref = useRef<any>();
  useFrame((state, delta) => (ref.current.rotation.x += 0.01));
  return (
    <mesh ref={ref}>
      <sphereGeometry args={[2, 4, 4]}></sphereGeometry>
      <meshStandardMaterial></meshStandardMaterial>
    </mesh>
  );
};

const Home = () => {
  return (
    <>
      {/* <MyCanvas>
        <ambientLight intensity={0.1} />
        <directionalLight color="purple" position={[0, 0, 5]} />

        <Box />
      </MyCanvas> */}

      <VideoWrapper>
        <Video autoPlay loop>
          <source src={LogoAni} type="video/mp4" />
        </Video>
      </VideoWrapper>

      <Gallery />
    </>
  );
};

export default Home;
