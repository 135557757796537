import styled from "styled-components";
import ProfilePic from "../assets/img/selfie.jpg";
import ProfilePic2 from "../assets/img/selfie2.jpg";
import SwitchImage from "./SwitchImage";

const AboutPage = styled.div`
  position: relative;
`;

const AboutMe = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-top: 3rem;
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
    flex-flow: row nowrap;
    margin-top: 10rem;
    margin-bottom: 5rem;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    margin: 10rem;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
    margin: 10rem;
    justify-content: center;
    align-items: center;
  }
`;

const AboutMeText = styled.div`
  margin: 1rem;
  font-size: 0.8rem;
  width: 19rem;
  @media (min-width: 640px) {
    width: 20rem;
  }

  @media (min-width: 768px) {
    width: 19rem;
    flex-shrink: 0;
    font-size: 1rem;
  }

  @media (min-width: 1024px) {
    width: 25rem;
  }

  @media (min-width: 1200px) {
    width: 32rem;
    flex-shrink: 0;
    margin-right: 6.5rem;
    font-size: 1.2rem;
  }
`;
const H1 = styled.h2`
  font-size: 1.2rem;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`;
const H1Center = styled.h2`
  font-size: 1.2rem;
  text-align: center;
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
`;
const Image = styled(SwitchImage)`
  position: relative;
  margin: 3rem;
  flex-shrink: 0;
  width: 300px;
  height: 400px;
  @media (min-width: 640px) {
    width: 350px;
    height: 450px;
  }

  @media (min-width: 768px) {
    width: 350px;
    height: 450px;
  }

  @media (min-width: 1024px) {
    width: 450px;
    height: 450px;
  }

  @media (min-width: 1200px) {
    height: 40rem;
    width: 30rem;
  }
`;
const AboutLinks = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: left;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
    margin: 1rem;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
const Link = styled.a`
  font-family: Main;
  margin: 0 1rem;
  text-decoration: lightpink underline;
  text-decoration-thickness: 2px;
  transition: color 0.2s;
  font-size: 1rem;
  color: white;

  :hover {
    text-decoration-thickness: 4px;
  }
  :visited {
  }
  @media (min-width: 640px) {
    font-size:1rem;

  }

  @media (min-width: 768px) {
    font-size:1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-family: Main;
    margin: 0 1rem;
    text-decoration: lightpink underline;
    text-decoration-thickness: 2px;
    transition: color 0.2s;
    font-size: 1.5rem;
    color: white;
`;

const About = () => {
  return (
    <AboutPage>
      <AboutMe>
        <AboutMeText>
          <p>
            Bruna Vasconcelos, aka brunaaart is a 3D artist from Brazil, who is
            currently based in Berlin.
          </p>
          <H1>
            In her artworks she explores diverse expressions of femininity,
            embedded in surreal landscapes, which come together with sprinkles
            of magical elements, as well as a cyberpunk touch.
          </H1>
          {/* <p>
            Bruna graduated in "Communication Design" at the SRH Berlin School
            of Design and Communication (BSDC).
          </p> */}
          {/* Bruna (Brunaaart) is a Brazilian 3D artist and visual designer, who is
          currently based in Berlin. Bruna’s work usually has a magical,
          mysterious and ethereal vibe, often representing women who are very
          powerful, emotional and yet delicate. Some other influences to her
          work are nature scenes, mythology and occasional cyberpunk elements. */}
          <H1>*:・ﾟ✧ ~</H1>
          <p>Contact Bruna at brunaaart@gmail.com</p>
        </AboutMeText>
        <Image />
      </AboutMe>
      <H1Center>Wanna see more?</H1Center>

      <AboutLinks>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.twitch.tv/brunaaart"
        >
          Twitch
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/watch?v=Eb34bbWsTtI&ab_channel=Maxon"
        >
          The 3D and Motion Design Show
        </Link>
      </AboutLinks>
      <AboutLinks>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.behance.net/bruna_v"
        >
          Behance
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.thecollection-gallery.com/product-page/bruna-vasconcelos-dripping-in-gold"
        >
          Limited Prints
        </Link>
      </AboutLinks>
    </AboutPage>
  );
};

export default About;
