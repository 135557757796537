import styled, { css, keyframes } from "styled-components";
import img1 from "../assets/img/gallery/summer-vibes.jpg";
import img2 from "../assets/img/gallery/cosmic-garden.jpg";
import img3 from "../assets/img/gallery/elf-girl.jpg";
import img4 from "../assets/img/gallery/mother_earth.mp4";
import img5 from "../assets/img/gallery/landscape-cloud.jpg";
import img6 from "../assets/img/gallery/universe.jpg";
import img7 from "../assets/img/gallery/euro-babe.jpg";
import img8 from "../assets/img/gallery/middle-finger.jpg";
import img9 from "../assets/img/gallery/summer-breeze.jpg";
import img10 from "../assets/img/gallery/bloom.mp4";
import img11 from "../assets/img/gallery/cars-and-girls3.jpg";
import img12 from "../assets/img/gallery/cars-and-girls.jpg";
import img13 from "../assets/img/gallery/cars-and-girls2.jpg";
import img14 from "../assets/img/gallery/cars-and-girls3.jpg";
import img15 from "../assets/img/gallery/pride-fairy.jpg";

import img16 from "../assets/img/gallery/thunder-goddess.jpg";
import img17 from "../assets/img/gallery/solar-goddess.jpg";
import img18 from "../assets/img/gallery/rain-goddess.jpg";
import img19 from "../assets/img/gallery/self-reflection.jpg";
import img20 from "../assets/img/gallery/dissociating.mp4";
import img21 from "../assets/img/gallery/mirror-selfie.jpg";
import img22 from "../assets/img/gallery/pastel-gothic.jpg";
import img23 from "../assets/img/gallery/3021.jpg";
import img24 from "../assets/img/gallery/card-healerwitch.jpg";
import img25 from "../assets/img/gallery/card-moonwarrior.jpg";
import img26 from "../assets/img/gallery/cosmic-love.jpg";

import img28 from "../assets/img/gallery/chained-angel.mp4";
import img29 from "../assets/img/gallery/andromeda.mp4";
import { useState } from "react";
import { ArrowDownRight } from "react-feather";

import { ReactComponent as ArrowRightBase } from "../assets/img/arrow-right.svg";
import { ReactComponent as ArrowLeftBase } from "../assets/img/arrow-left.svg";
import { ReactComponent as XButtonBase } from "../assets/img/x.svg";

const svgStyle = css`
  position: relative;
  margin: 0rem;
  // box-shadow: 10px 10px;
  width: 10%;
  height: auto;
 
  }
  @media (min-width: 640px) {
    margin: 1rem;
    position: relative;
    width: auto;
    height: auto;
  }

  @media (min-width: 768px) {
    margin: 2rem;
    position: relative;
    width: auto;
    height: auto;
  }

  @media (min-width: 1024px) {
    margin: 2rem;
    position: relative;
    width: auto;
    height: auto;
  }

  @media (min-width: 1200px) {
    stroke: white;
    position: relative;
    margin: 2rem;
    flex-shrink: 0;
    cursor: pointer;
    width: auto;
    height: auto;
  }
`;

const ArrowRight = styled(ArrowRightBase)<{ hidden: boolean }>`
  ${svgStyle}
  // margin-left: 19rem;
opacity: ${(props) => (props.hidden ? 0 : 0.5)};

  :active {
    opacity: ${(props) => (props.hidden ? 0 : 1)};
  }

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    //  margin: 5rem;
  }
`;
const ArrowLeft = styled(ArrowLeftBase)<{ hidden: boolean }>`
  ${svgStyle}
  //margin-right: 19rem;
  //z-index: 99;
opacity: ${(props) => (props.hidden ? 0 : 0.5)};

  :active {
    opacity: ${(props) => (props.hidden ? 0 : 1)};
  }

  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    // margin: 5rem;
  }
`;

const XButton = styled(XButtonBase)`
  stroke: white;

  top: 1rem;
  right: 1rem;
  flex-shrink: 0;
  position: fixed;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
  }
`;
const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const zoomIn = keyframes`
0%{transform: scale(40%,40%); opacity:0;}
100%{transform: scale(100% 100%);opacity:1;}
`;

const ModalItemWrapper = styled.figure`
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: stretch;
  margin: 0;
  padding: 0;
  animation: ${zoomIn} 0.2s ease-out;
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    align-self: stretch;
    margin: 0;
    padding: 0;
    animation: ${zoomIn} 0.2s ease-out;
  }
`;

const modalItemsStyle = css`
  position: absolute;
  object-fit: contain;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  @media (min-width: 640px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1200px) {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;
const ModalImg = styled.img`
  ${modalItemsStyle}
`;

const ModalVideo = styled.video`
  ${modalItemsStyle}
`;

const GalleryImg = styled.img<{ spans: number }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  grid-column: span ${(props) => props.spans};
  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const GalleryVideo = styled.video<{ spans: number }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  grid-column: span ${(props) => props.spans};
  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;

//
const GalleryGrid = styled.div`
  display: grid;
  gap: 0rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: dense;
  position: relative;
`;

interface GalleryItem {
  spans: number;
  src: string;
  type: "img" | "video";
}

const galleryItems: GalleryItem[] = [
  { src: img1, spans: 6, type: "img" },

  { src: img2, spans: 3, type: "img" },
  { src: img3, spans: 3, type: "img" },

  { src: img4, spans: 3, type: "video" },
  { src: img5, spans: 3, type: "img" },

  { src: img21, spans: 2, type: "img" },
  { src: img20, spans: 2, type: "video" },
  { src: img19, spans: 2, type: "img" },

  { src: img7, spans: 3, type: "img" },
  { src: img8, spans: 3, type: "img" },

  { src: img16, spans: 2, type: "img" },
  { src: img17, spans: 2, type: "img" },
  { src: img18, spans: 2, type: "img" },

  { src: img29, spans: 2, type: "video" },
  { src: img6, spans: 4, type: "img" },

  { src: img26, spans: 3, type: "img" },
  { src: img28, spans: 3, type: "video" },

  { src: img12, spans: 2, type: "img" },
  { src: img13, spans: 2, type: "img" },
  { src: img14, spans: 2, type: "img" },

  { src: img10, spans: 3, type: "video" },
  { src: img23, spans: 3, type: "img" },

  { src: img15, spans: 2, type: "img" },
  { src: img22, spans: 2, type: "img" },
  { src: img9, spans: 2, type: "img" },
];

const add = (num1: number, num2: number) => {
  return num1 + num2;
};

const setCallback = (callback: (param: number) => string) => {};

setCallback((num) => `hello ${num}`);

const Gallery = () => {
  const [popupItem, setPopupItem] = useState(-1);
  //POP UP image and video are defined here. When popupItem is defined (NOT undefined), then render ModalImg or ModalVideo(the pop up version of the gallery img and videos)
  const isFirstItem = popupItem === 0;
  const isLastItem = popupItem === galleryItems.length - 1;
  return (
    <GalleryGrid>
      {popupItem !== -1 && (
        <Modal>
          <ArrowLeft
            onClick={(event: MouseEvent) => {
              if (!isFirstItem) {
                setPopupItem((current) => current - 1);
                event.stopPropagation();
              }
            }}
            hidden={isFirstItem}
          />

          {galleryItems[popupItem].type === "img" ? (
            <ModalItemWrapper>
              <ModalImg src={galleryItems[popupItem].src} />
            </ModalItemWrapper>
          ) : (
            <ModalItemWrapper>
              <ModalVideo autoPlay loop>
                <source src={galleryItems[popupItem].src} type="video/mp4" />{" "}
              </ModalVideo>
            </ModalItemWrapper>
          )}

          <ArrowRight
            onClick={(event: MouseEvent) => {
              if (!isLastItem) {
                setPopupItem((current) => current + 1);
                event.stopPropagation();
              }
            }}
            hidden={isLastItem}
          />

          <XButton onClick={() => setPopupItem(-1)} />
        </Modal>
      )}

      {/* Here the GALLERY is rendered. We take the galleryItems from the array of objects
      we wrote up on the code, with the .map function (that goes through all array elements) . then we ask with an if statement, if the type of the item is an img, if yes it returns a gallery img, if not it returs a gallery video.
       */}
      {galleryItems.map((item, index) => {
        if (item.type === "img") {
          return (
            <GalleryImg
              src={item.src}
              spans={item.spans}
              key={index}
              onClick={() => setPopupItem(index)}
            />
          );
        } else {
          return (
            <GalleryVideo
              spans={item.spans}
              key={index}
              autoPlay
              loop
              onClick={() => setPopupItem(index)}
            >
              <source src={item.src} type="video/mp4" />
            </GalleryVideo>
          );
        }
      })}
    </GalleryGrid>
  );
};

export default Gallery;
